(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeNoDialogController', GuaranteeNoDialogController);

    GuaranteeNoDialogController.$inject = ['$timeout', '$scope', '$state', '$filter', '$uibModal', 'entity', 'Guarantee', 'GuaranteeType', 'TransportType', 'ObjectType', 'Country', 'AdValoremDocument', 'GuaranteeStatus', 'Principal', 'Facility', 'Contract', 'Zone'];

    function GuaranteeNoDialogController ($timeout, $scope, $state, $filter, $uibModal, entity, Guarantee, GuaranteeType, TransportType, ObjectType, Country, AdValoremDocument, GuaranteeStatus, Principal, Facility, Contract, Zone) {
        var vm = this;

        vm.guarantee = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.guaranteetypes = GuaranteeType.query();
        vm.transporttypes = TransportType.query();
        vm.objecttypes = ObjectType.query();
        vm.countries = Country.getAll();
        vm.guaranteestatuses = GuaranteeStatus.query();
        vm.guarantee.vehiculeValue = 0;
        vm.modalResInstance = null;
        vm.zone1Name = {};
        vm.transportTypeTerrestreName = {};
        vm.facilities = [];
        vm.contract = {};
        vm.validateArtObject = validateArtObject;
        vm.validateAccessibleByRoad = validateAccessibleByRoad;
        vm.validateAtLeastOneCountryIsInZone1 = validateAtLeastOneCountryIsInZone1;
        vm.validateIsInfToMaxGlobalValue = validateIsInfToMaxGlobalValue;
        vm.validateIsInfToMaxObjectValue = validateIsInfToMaxObjectValue;
        vm.validateIfNbossMustBeZero = validateIfNbossMustBeZero;
        vm.validateIfVtossMustBeZero = validateIfVtossMustBeZero;
        vm.validateEffectiveDate = validateEffectiveDate;
        vm.updateMinVtossValue = updateMinVtossValue;

        // Set today's date at midnight
        vm.today = new Date();
        vm.today.setHours(0);
        vm.today.setMinutes(0);
        vm.today.setSeconds(0);
        vm.today.setMilliseconds(0);

        // Min possible value of vtoss
        vm.minVtossValue = 0;

        vm.zone1Name = Zone.getZone1Name();
        vm.transportTypeTerrestreName = TransportType.getTransportTypeTerrestreName();

        Principal.identity().then(function(account) {
            vm.guarantee.userLogin = account.login;
            getFacilityOfUser(encodeURIComponent(vm.guarantee.userLogin));
            getContractOfUser(encodeURIComponent(vm.guarantee.userLogin));
        });

        // Titre du formulaire selon devis ou demande de garantie
        if (vm.guarantee.isDevis) {
            vm.title = $filter('translate')('adValoremApp.guarantee.devisPageTitle');
            vm.btnSubmitLabel = $filter('translate')('adValoremApp.guarantee.submitDevis');
        } else {
            vm.title = $filter('translate')('adValoremApp.guarantee.home.createLabel');
            vm.btnSubmitLabel = $filter('translate')('adValoremApp.guarantee.submit');
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function getFacilityOfUser(userLogin) {
            vm.facilities = Facility.getByUserLogin({userLogin: userLogin});
        }

        function getContractOfUser(userLogin) {
            vm.contract = Contract.getByUserLogin({userLogin: userLogin});
        }

        function clear (){
            $state.go('home', null, { relative: false });
        }

        function save () {
            vm.isSaving = true;
            Guarantee.save(vm.guarantee, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('adValoremApp:guaranteeUpdate', result);
            openModalRes(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.effectiveDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function openModalRes (resGuarantee) {
            vm.modalResInstance = $uibModal.open({
                animation: true,
                ariaLabelledBy: 'res-guarantee-modal-title',
                ariaDescribedBy: 'res-guarantee-modal-body',
                templateUrl: 'resGuaranteeModal.html',
                controller: 'GuaranteeResModalController',
                controllerAs: 'vm',
                size: 'lg',
                windowClass: 'guarantee-modal-res',
                //appendTo: parentElem,
                resolve: {
                    items: function () {
                        return resGuarantee;
                    }
                }
            });
        }

        function validateArtObject (value) {
            return value != 'true';
        }

        function validateAccessibleByRoad (value) {
            var isAccessible = true;
            if (vm.guarantee.transportType != null && value != null) {
                if(vm.guarantee.transportType.name == vm.transportTypeTerrestreName.transportTypeTerrestre) {
                    isAccessible = (value.zone.name == vm.zone1Name.zone1Name);
                }
            }
            return isAccessible;
        }

        function validateAtLeastOneCountryIsInZone1 (value, otherCountry) {
            var atLeastOneCountryIsInZone1 = true;
            if (value != null && otherCountry != null) {
                atLeastOneCountryIsInZone1 = (value.zone.name == vm.zone1Name.zone1Name) || (otherCountry.zone.name == vm.zone1Name.zone1Name);
            }
            return atLeastOneCountryIsInZone1;
        }

        function validateIsInfToMaxGlobalValue (value) {
            var isInfToMaxGlobalValue = true;
            if (value != null) {
                isInfToMaxGlobalValue = (value <= vm.contract.maxGlobalValue);
            }
            return isInfToMaxGlobalValue;
        }

        function validateIsInfToMaxObjectValue (value) {
            var isInfToMaxObjectValue = true;
            if (value != null && vm.guarantee.nboss != null && vm.guarantee.nboss > 0) {
                isInfToMaxObjectValue = (value / vm.guarantee.nboss) <= vm.contract.maxObjectValue;
            }
            return isInfToMaxObjectValue;
        }

        function validateIfNbossMustBeZero (value) {
            var nbossIsValid = true;
            if (value != null && vm.guarantee.vtoss != null){
                if (value > 0) {
                    nbossIsValid = vm.guarantee.vtoss > 0;
                }
            }
            return nbossIsValid;
        }

        function validateIfVtossMustBeZero (value) {
            var vtossIsValid = true;
            if (value != null && vm.guarantee.nboss != null){
                if (value > 0) {
                    vtossIsValid = vm.guarantee.nboss > 0;
                }
            }
            return vtossIsValid;
        }

        function validateEffectiveDate (value) {
            var isAtLeastToday = true;
            if (value != null) {
                isAtLeastToday = (value >= vm.today);
            }
            return isAtLeastToday;
        }

        function updateMinVtossValue () {
            if (vm.guarantee.nboss != null) {
                vm.minVtossValue = vm.guarantee.nboss * vm.contract.minObjectValue;
            }
        }
    }
})();

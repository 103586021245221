(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ContractDialogController', ContractDialogController);

    ContractDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Contract', 'Client', 'ContractStatus'];

    function ContractDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Contract, Client, ContractStatus) {
        var vm = this;

        vm.contract = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.verifyDueDateFormat = verifyDueDateFormat;
        vm.verifyDueDateDay = verifyDueDateDay;
        vm.verifyDueDateMonth = verifyDueDateMonth;
        vm.clients = Client.getAll({filter: 'contract-is-null'});
        $q.all([vm.contract.$promise, vm.clients.$promise]).then(function() {
            if (!vm.contract.client || !vm.contract.client.id) {
                return $q.reject();
            }
            return Client.get({id : vm.contract.client.id}).$promise;
        }).then(function(client) {
            vm.clients.push(client);
        });
        vm.contractstatuses = ContractStatus.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.contract.id !== null) {
                Contract.update(vm.contract, onSaveSuccess, onSaveError);
            } else {
                Contract.save(vm.contract, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('adValoremApp:contractUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dueDate = false;
        vm.datePickerOpenStatus.cancellationDate = false;
        vm.datePickerOpenStatus.suspensionDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function verifyDueDateFormat (value) {
            var isFormatValid = true;
            if (value != null) {
                isFormatValid = (value.search(/^\d{2}\/\d{2}$/) >= 0);
            }
            return isFormatValid;
        }

        function verifyDueDateDay (value) {
            var isDueDateDayValid = true;
            if (value != null) {
                var dateArray = value.split('/');
                if (dateArray.length == 2) {
                    var day = parseInt(dateArray[0]);
                    isDueDateDayValid = (day >= 1 && day <= 31);
                }
            }
            return isDueDateDayValid;
        }

        function verifyDueDateMonth (value) {
            var isDueDateMonthValid = true;
            if (value != null) {
                var dateArray = value.split('/');
                if (dateArray.length == 2) {
                    var month = parseInt(dateArray[1]);
                    isDueDateMonthValid = (month >= 1 && month <= 12);
                }
            }
            return isDueDateMonthValid;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ClientReactivateController',ClientReactivateController);

    ClientReactivateController.$inject = ['$uibModalInstance', 'entity', 'Client'];

    function ClientReactivateController($uibModalInstance, entity, Client) {
        var vm = this;

        vm.client = entity;
        vm.clear = clear;
        vm.lockForm = lockForm;
        vm.disabled = false;
        vm.confirmReactivate = confirmReactivate;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function lockForm () {
            vm.disabled = true
        }

        function confirmReactivate (id) {
            Client.reactivate({id: id},
                function () {
                    $uibModalInstance.close(true);
                },
                lockForm
            );
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('DommageRateDetailController', DommageRateDetailController);

    DommageRateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DommageRate', 'TransportType', 'Zone', 'Client'];

    function DommageRateDetailController($scope, $rootScope, $stateParams, previousState, entity, DommageRate, TransportType, Zone, Client) {
        var vm = this;

        vm.dommageRate = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('adValoremApp:dommageRateUpdate', function(event, result) {
            vm.dommageRate = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

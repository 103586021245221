(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'LoginService'];

    function ResetFinishController ($stateParams, $timeout, Auth, LoginService) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.checkPassword = checkPassword;
        vm.checkNotContainsAccents = checkNotContainsAccents;
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#password').focus();});

        function checkPassword(value) {
            var passwordValid = true;
            if(value != null) {
                if (value.search(/[A-Z]/) == -1) {
                    // le mot de passe doit contenir au moins une lettre majuscule
                    passwordValid = false;
                }
                if (value.search(/[a-z]/) == -1) {
                    // le mot de passe doit contenir au moins une lettre minuscule
                    passwordValid = false;
                }
                if (value.search(/\d/) == -1) {
                    // le mot de passe doit contenir au moins un chiffre
                    passwordValid = false;
                }
                if (value.search(/\W/) == -1) {
                    // le mot de passe doit contenir au moins un caractère spécial
                    passwordValid = false;
                }
            }

            return passwordValid;
        }

        function checkNotContainsAccents(value) {
            // le mot de passe ne doit pas contenir de caractères accentués
            if (value != null) {
                return value.search(/[À-ÿ]/) == -1;
            } else {
                return true;
            }
        }

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();

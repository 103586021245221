(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('AccsUserDetailController', AccsUserDetailController);

    AccsUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AccsUser', 'User', 'Client'];

    function AccsUserDetailController($scope, $rootScope, $stateParams, previousState, entity, AccsUser, User, Client) {
        var vm = this;

        vm.accsUser = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('adValoremApp:accsUserUpdate', function(event, result) {
            vm.accsUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

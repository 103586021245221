(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeCancelController',GuaranteeCancelController);

    GuaranteeCancelController.$inject = ['$uibModalInstance', 'entity', 'Guarantee'];

    function GuaranteeCancelController($uibModalInstance, entity, Guarantee) {
        var vm = this;

        vm.guarantee = entity;
        vm.clear = clear;
        vm.confirmCancel = confirmCancel;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCancel (id) {
            Guarantee.cancel({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

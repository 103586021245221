(function() {
    'use strict';
    angular
        .module('adValoremApp')
        .factory('Guarantee', Guarantee);

    Guarantee.$inject = ['$resource', 'DateUtils'];

    function Guarantee ($resource, DateUtils) {
        var resourceUrl =  'api/guarantees/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.effectiveDate = DateUtils.convertLocalDateToServer(copy.effectiveDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.effectiveDate = DateUtils.convertLocalDateToServer(copy.effectiveDate);
                    return angular.toJson(copy);
                }
            },
            'cancel': {
                method: 'POST',
                params: {id: '@id'},
                url: 'api/guarantees/cancel/:id'
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ZoneDetailController', ZoneDetailController);

    ZoneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Zone', 'Country'];

    function ZoneDetailController($scope, $rootScope, $stateParams, previousState, entity, Zone, Country) {
        var vm = this;

        vm.zone = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('adValoremApp:zoneUpdate', function(event, result) {
            vm.zone = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeDetailController', GuaranteeDetailController);

    GuaranteeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Guarantee', 'GuaranteeType', 'TransportType', 'ObjectType', 'Country', 'AdValoremDocument', 'GuaranteeStatus', 'Client', 'Facility'];

    function GuaranteeDetailController($scope, $rootScope, $stateParams, previousState, entity, Guarantee, GuaranteeType, TransportType, ObjectType, Country, AdValoremDocument, GuaranteeStatus, Client, Facility) {
        var vm = this;

        vm.guarantee = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('adValoremApp:guaranteeUpdate', function(event, result) {
            vm.guarantee = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .factory('GuaranteeSearch', GuaranteeSearch);

    GuaranteeSearch.$inject = ['$resource'];

    function GuaranteeSearch($resource) {
        var resourceUrl =  'api/_search/guarantees/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

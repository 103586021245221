(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal'];

    function PasswordController (Auth, Principal) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.checkPassword = checkPassword;
        vm.checkNotContainsAccents = checkNotContainsAccents;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function checkPassword(value) {
            var passwordValid = true;
            if(value != null) {
                if (value.search(/[A-Z]/) == -1) {
                    // le mot de passe doit contenir au moins une lettre majuscule
                    passwordValid = false;
                }
                if (value.search(/[a-z]/) == -1) {
                    // le mot de passe doit contenir au moins une lettre minuscule
                    passwordValid = false;
                }
                if (value.search(/\d/) == -1) {
                    // le mot de passe doit contenir au moins un chiffre
                    passwordValid = false;
                }
                if (value.search(/\W/) == -1) {
                    // le mot de passe doit contenir au moins un caractère spécial
                    passwordValid = false;
                }
            }

            return passwordValid;
        }

        function checkNotContainsAccents(value) {
            // le mot de passe ne doit pas contenir de caractères accentués
            if (value != null) {
                return value.search(/[À-ÿ]/) == -1;
            } else {
                return true;
            }
        }

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }
    }
})();

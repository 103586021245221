(function() {
    'use strict';
    angular
        .module('adValoremApp')
        .factory('AdValoremDocument', AdValoremDocument);

    AdValoremDocument.$inject = ['$resource'];

    function AdValoremDocument ($resource) {
        var resourceUrl =  'api/ad-valorem-documents/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryByLogin': {
                method: 'GET',
                isArray: true,
                params: {userLogin: 'userLogin'}
            }
        });
    }
})();

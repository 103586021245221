(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ClientSummaryController', ClientSummaryController);

    ClientSummaryController.$inject = ['$timeout', '$state', '$filter', 'entity', 'Client'];

    function ClientSummaryController ($timeout, $state, $filter, entity, Client) {
        var vm = this;

        vm.client = entity;
        vm.datePickerOpenStatus = {};
        vm.clear = clear;
        vm.save = save;
        vm.openCalendar = openCalendar;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.datePickerOpenStatus.period = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear () {
            $state.go('home', null, { relative: false });
        }

        function save () {
            vm.isSaving = true;
            var formattedDate = $filter('date')(vm.period, 'dd/MM/yyyy');
            Client.generateSummary({period: formattedDate}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $state.go('home', null, { relative: false });
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('AccsUserDialogController', AccsUserDialogController);

    AccsUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'AccsUser', 'User', 'Client'];

    function AccsUserDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, AccsUser, User, Client) {
        var vm = this;

        vm.accsUser = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = User.getAll();
        vm.clients = Client.getAll();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.accsUser.id !== null) {
                AccsUser.update(vm.accsUser, onSaveSuccess, onSaveError);
            } else {
                AccsUser.save(vm.accsUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('adValoremApp:accsUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeTypeDeleteController',GuaranteeTypeDeleteController);

    GuaranteeTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'GuaranteeType'];

    function GuaranteeTypeDeleteController($uibModalInstance, entity, GuaranteeType) {
        var vm = this;

        vm.guaranteeType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            GuaranteeType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();

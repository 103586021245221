(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeDialogController', GuaranteeDialogController);

    GuaranteeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Guarantee', 'GuaranteeType', 'TransportType', 'ObjectType', 'Country', 'AdValoremDocument', 'GuaranteeStatus', 'Client', 'Facility'];

    function GuaranteeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Guarantee, GuaranteeType, TransportType, ObjectType, Country, AdValoremDocument, GuaranteeStatus, Client, Facility) {
        var vm = this;

        vm.guarantee = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.guaranteetypes = GuaranteeType.query();
        vm.transporttypes = TransportType.query();
        vm.objecttypes = ObjectType.query();
        vm.countries = Country.getAll();
        vm.guaranteestatuses = GuaranteeStatus.query();
        vm.clients = Client.getAll();
        vm.facilities = Facility.getAll();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            Guarantee.update(vm.guarantee, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('adValoremApp:guaranteeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.effectiveDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

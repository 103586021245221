(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .factory('DommageRateSearch', DommageRateSearch);

    DommageRateSearch.$inject = ['$resource'];

    function DommageRateSearch($resource) {
        var resourceUrl =  'api/_search/dommage-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeTypeDetailController', GuaranteeTypeDetailController);

    GuaranteeTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'GuaranteeType'];

    function GuaranteeTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, GuaranteeType) {
        var vm = this;

        vm.guaranteeType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('adValoremApp:guaranteeTypeUpdate', function(event, result) {
            vm.guaranteeType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

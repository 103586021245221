(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .factory('AdValoremDocumentSearch', AdValoremDocumentSearch);

    AdValoremDocumentSearch.$inject = ['$resource'];

    function AdValoremDocumentSearch($resource) {
        var resourceUrl =  'api/_search/ad-valorem-documents/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryByLogin': {
                method: 'GET',
                params: {userLogin: 'userLogin'},
                isArray: true
            }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('adValoremApp')
        .factory('Client', Client);

    Client.$inject = ['$resource'];

    function Client ($resource) {
        var resourceUrl =  'api/clients/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'suspend': {
                method: 'POST',
                params: {id: '@id'},
                url: 'api/clients/suspend/:id'
            },
            'reactivate': {
                method: 'POST',
                params: {id: '@id'},
                url: 'api/clients/reactivate/:id'
            },
            'generateSummary': {
                method: 'POST',
                params: {summaryDate: '@period'},
                url: 'api/clients/summary/generate'
            },
            'getAll': {
                method: 'GET',
                isArray: true,
                url: 'api/clients?size=1000'
            }
        });
    }
})();

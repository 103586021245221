(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('AdValoremDocumentDetailController', AdValoremDocumentDetailController);

    AdValoremDocumentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'AdValoremDocument', 'Client'];

    function AdValoremDocumentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, AdValoremDocument, Client) {
        var vm = this;

        vm.adValoremDocument = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('adValoremApp:adValoremDocumentUpdate', function(event, result) {
            vm.adValoremDocument = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('GuaranteeResModalController', GuaranteeResModalController);

    GuaranteeResModalController.$inject = ['$uibModalInstance', 'items', '$filter'];

    function GuaranteeResModalController ($uibModalInstance, items, $filter) {
        var vm = this;

        vm.guarantee = items;
        vm.closeModalRes = closeModalRes;

        if (vm.guarantee.isDevis != null && vm.guarantee.isDevis) {
            vm.headerModalRes = $filter('translate')('adValoremApp.guarantee.modalRes.headerDevis');
        } else {
            vm.headerModalRes = $filter('translate')('adValoremApp.guarantee.modalRes.headerGuarantee');
        }

        function closeModalRes() {
            $uibModalInstance.close('Closing modal');
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('adValoremApp')
        .controller('ObjectTypeDeleteController',ObjectTypeDeleteController);

    ObjectTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'ObjectType'];

    function ObjectTypeDeleteController($uibModalInstance, entity, ObjectType) {
        var vm = this;

        vm.objectType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ObjectType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
